
import {defineComponent, ref, onMounted} from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import {IFactory} from "@/store/modules/FactoryModule";
import AddFactoryModal from "@/views/factory/modals/AddFactoryModal.vue";
import {MenuComponent} from "@/assets/ts/components";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import {Api} from "@/store/enums/StoreEnums";


export default defineComponent({
  name: "FactoryListing",
  components: {
    Datatable,
    AddFactoryModal,
  },
  setup() {
    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Přehled středisek", ["Střediska"]);
    });

    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: "Středisko",
        key: "company_name",
        sortable: true,
      },
      {
        name: "Adresa",
        key: "address",
        sortable: true,
      },
      {
        name: "IČ",
        key: "ic",
        sortable: true,
      },
      {
        name: "Číslo střediska",
        key: "factory_number",
        sortable: true,
      },
      {
        name: "Kontaktní osoba",
        key: "contact_person",
      },
      {
        name: "Akce",
        key: "action",
      },
    ]);

    const search = ref<string>("");

    return {
      tableHeader,
      search
    };
  },
  data() {
    return {
      factory: [],
      factoryCount: 0,
      checkedFactory: []
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData(){
      ApiService.get(Api.ALL_FACTORIES).then(({data}) => {
        this.factory = data;
        (this.$refs['datatable_component'] as any).setData(data);

        this.factoryCount = data.length;
      }).catch(({response}) => {
        console.error(response.data.errors);
      });
    },
    deleteFactory(id) {
      let factory: Array<IFactory> = this.factory;
      for (let i = 0; i < factory.length; i++) {
        if (factory[i].id === id) {
          factory.splice(i, 1);
        }
      }
    },
    deleteSelectedFactory() {
      let checkedFactory: Array<IFactory> = this.checkedFactory;
      checkedFactory.forEach((item) => {
        this.deleteFactory(item);
      });
      checkedFactory.length = 0;
    },
    searchItems() {
      let factory: Array<IFactory> = this.factory;

      if (this.search !== "") {
        let results: Array<IFactory> = [];
        for (let j = 0; j < factory.length; j++) {
          if (this.searchingFunc(factory[j], this.search)) {
            results.push(factory[j]);
          }
        }
        factory.splice(0, factory.length, ...results);
      } else {
          this.loadData();
      }

    },
    searchingFunc(obj, value) {
      for (let key in obj) {
        console.log(Number.isInteger(obj[key]));
        if ((!Number.isInteger(obj[key]) || (Number.isInteger(value)))&& !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    }
  },


});
