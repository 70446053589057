const rules = {
    company_name: [
        {
            required: true,
            message: "Název společnosti je povinný",
            trigger: "change",
        },
    ],
    address: [
        {
            required: true,
            message: "Adresa je povinná",
            trigger: "change",
        },
    ],
    ic: [
        {
            required: true,
            message: "IČ je povinné",
            trigger: "change",
        },
    ],
    work_location: [
        {
            required: true,
            message: "Místo výkonu pracé je povinné",
            trigger: "change",
        },
    ],
    factory_number: [
        {
            required: true,
            message: "Číslo střediska je povinné",
            trigger: "change",
        },
    ],
};

const data = {
    company_name: "",
    id: "",
    ic: "",
    dic: "",
    address: "",
    work_location: "",
    factory_number: "",
    contact_person: ""
}

export interface IFactory {
    company_name: string,
    id: number,
    ic: string,
    dic: string,
    address: string,
    work_location: string,
    factory_number: string,
    contact_person: string
};


export const definition = {
    company_name: 'string',
    id: 'number',
    dic: 'string',
    ic: 'string',
    address: 'string',
    work_location: 'string',
    factory_number: 'string',
    contact_person: 'string'
};

export {rules, data}
