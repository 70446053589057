
import {defineComponent, ref} from "vue";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import {data as factoryData, rules as factoryRules, definition as factoryDefinition} from "@/model/factory"
import {castData} from "@/core/data/data_prepare";

export default defineComponent({
  name: "AddFactoryModal",
  components: {},
  setup() {
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const addFactoryModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const formData = ref(factoryData);
    const rules = ref(factoryRules);

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          let data = castData(formData.value, factoryDefinition)
          store
            .dispatch(Actions.ADD_FACTORY, data)
            .then(() => {
              Swal.fire({
                text: "Vaše nové střediko může používat",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Středisko bylo přidáno",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(function () {
                // Go to page after successfully login
                loading.value = false;
                hideModal(addFactoryModalRef.value);
              });
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addFactoryModalRef,
    };
  },
});
